import React from 'react';
import Promotion from '@clearscore/website.spring-promotion-landing';
import type { PageProps } from 'gatsby';

import avatar from '../assets/gb/avatar_gb_250x250.jpg';
import Layout from '../components/layout';

const PromotionTemplate = ({ location }: PageProps): React.ReactElement => (
    <Layout
        meta={{
            title: 'ClearScore',
            description: '',
            image: avatar,
        }}
        location={location.pathname}
    >
        {(): React.ReactElement => <Promotion />}
    </Layout>
);

export default PromotionTemplate;
