import { useEffect } from 'react';
import { navigate } from 'gatsby';

/**
 * Leaving this vertical in place as gatsby redirects are not working with
 * this existing page
 */
const SpringPromotionLandingPage = () => {
    useEffect(() => {
        navigate('/');
    }, []);

    return null;
};

export default SpringPromotionLandingPage;
